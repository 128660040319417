import React from 'react'
import styled from 'styled-components';
import Profile from '../assets/Company-Brochure.pdf';
import { MdFileDownload } from 'react-icons/md';


const AboutWrapper = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top : 10px;
  
  @media only screen and (max-width: 480px) {
    text-align: center;
  }
 `;

 const Heading = styled.div`
  
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  background-color: #00008B;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  text-align: center;

  @media only screen and (max-width: 480px) {
    font-size: 10px;
    text-align: center;
  }
 `;

 const Para = styled.div`
  
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  color: black;
  margin-top : 5px;
  font-size: 20px;
  

  @media only screen and (max-width: 480px) {
    font-size: 10px;
    text-align: center;
  }
 `;

 const HeadingText = styled.h1 `
 `;

 const ParaText = styled.h3 `
   font-family: 'Roboto', sans-serif;
   font-weight: bolder;
`;

const Downloadicon = styled(MdFileDownload)`
  font-size: 30px;
  margin-top: 2px;
  color: yellow;
`;

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  #00008B;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  margin-top: 0.5rem;
`;

const ButtonText = styled.span`
  margin-right: 10px;
`;




const About = () => {
  return (
    <AboutWrapper>
       <Heading>
        <HeadingText>About Us</HeadingText>
       </Heading>

       <Para>
        <ParaText>Aakash Deep Packers and Movers, take pride in being your dedicated packers and movers in all your relocation needs. With more than 10 years of the moving industry, we have established ourselves as a trusted and reliable packers and movers, committed to delivering seamless moving solutions.  We provide packing, loading, transportation, unloading, car or bike transport, home relocation, and office relocation services. Whether you're moving locally or across India, we are here to provide you with a stress-free experience and a sense of security for your cherished possessions. In Rajasthan, we have established our packers and movers offices in Ajmer, Jaipur, Alwar, Udaipur, and Jodhpur. Additionally, we also have offices in Ahmedabad and Gurugram. Our packers and movers services extend across India, covering the entire nation.</ParaText>
       </Para>

       <Button href={Profile} download> 
        <ButtonText>Download Company Profile</ButtonText>
        <Downloadicon />
       </Button>

       

    </AboutWrapper>
  )
}

export default About
